<template>
  <ClientOnly>
    <Teleport to=".modals">
      <div
        :class="[
          'modal',
          { 'is-open': isOpen, 'height-auto': heightAuto },
          cssClass,
        ]"
        :aria-hidden="!isOpen"
        @click="closeModal"
      >
        <div class="modal-inner section" @click.stop>
          <button ref="closeButton" class="modal-close" @click="closeModal">
            <SvgIcon name="func_cross" />
          </button>
          <div class="modal-content-wrapper">
            <div class="modal-content">
              <slot />
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </ClientOnly>
</template>

<script setup>
const props = defineProps({
  cssClass: {
    type: String,
    required: false,
    default: "",
  },
  heightAuto: {
    type: Boolean,
    required: false,
  },
  name: {
    type: String,
    required: false,
    default: "",
  },
});

const isOpen = ref(false);

const closeButton = ref(null);
const openButton = ref(null);

const { updateIsModalOpen } = inject("modal");

const emit = defineEmits(["close"]);

const closeModal = () => {
  isOpen.value = false;
  updateIsModalOpen();
  openButton.value?.focus();
  emit("close");
};

const openModal = () => {
  isOpen.value = true;
  openButton.value = document.activeElement;
  updateIsModalOpen();
  setTimeout(() => {
    closeButton.value.focus();
  });
};

defineExpose({
  openModal,
  closeModal,
  isOpen: readonly(isOpen),
});
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  background-color: $background-overlay;
  @include inset(0);
  z-index: $z-index-modal;

  &:not(.is-open) {
    visibility: hidden;
  }

  &-inner {
    position: absolute;
    @include pair-7;
    display: flex;
    flex-direction: column;
    @include inset(1.5rem, 0, 0);
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    padding-top: 4rem;

    @include for-tablet-landscape-up {
      @include inset(2.25rem);
      border-radius: $radius-m;
      padding-top: 1rem;
      padding-bottom: 1rem;
    }
  }

  &.height-auto .modal-inner {
    max-height: calc(100% - 1.5rem);
    top: auto;

    @include for-tablet-landscape-up {
      max-height: calc(100% - 4.5rem);
      top: auto;
    }
  }

  &-content-wrapper {
    height: 100%;
    overflow: auto;
  }

  &-content {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 4rem;

    @include for-tablet-landscape-up {
      padding: 4rem 17.5vw 5rem;
    }
  }

  @include for-tablet-landscape-down {
    &-content-wrapper {
      display: grid;
      grid-template-columns: repeat($nb-columns, 1fr);
      grid-auto-rows: minmax(max-content, 100%);
    }

    &-content {
      grid-column: 3/-3;
    }
  }

  &-close {
    position: absolute;
    top: 1.125rem;
    right: 1.125rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    z-index: 1;
  }

  button {
    background: none;
    border: none;
    padding: 0;
  }

  &.contactModal {
    .modal-content {
      padding-right: 2.5vw;
      padding-left: 2.5vw;
      @include for-tablet-landscape-up {
        padding-right: 12.5vw;
        padding-left: 12.5vw;
      }
    }
  }
}
</style>
